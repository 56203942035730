<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_returnSlipApproval" />
      <v-form v-model="isInputCheck" ref="form">
        <v-container fluid>
          <v-row class="search-row">
            <!-- 取引先-->
            <div class="search-autocomplete first-search-item">
              <div v-if="suppliersSelectedName.length > 15">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div style="height: 20px" v-bind="attrs" v-on="on">
                      <v-text-field
                        dense
                        id="get-supplier"
                        v-model="suppliersSelectedName"
                        :label="$t('label.lbl_supplier')"
                        class="txt-single"
                        readonly
                        persistent-hint
                      ></v-text-field>
                    </div>
                  </template>
                  <span>{{ suppliersSelectedName }} </span>
                </v-tooltip>
              </div>
              <div v-else>
                <div>
                  <v-text-field
                    dense
                    id="get-supplier"
                    v-model="suppliersSelectedName"
                    :label="$t('label.lbl_supplier')"
                    class="txt-single"
                    readonly
                    persistent-hint
                  >
                  </v-text-field>
                </div>
              </div>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 出荷先-->
            <div class="search-autocomplete">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div style="height: 20px" v-bind="attrs" v-on="on">
                    <v-text-field
                      readonly
                      dense
                      v-model="shippingSelectedName"
                      :items="shippingList"
                      :label="$t('label.lbl_shippingTo')"
                      class="txt-single"
                      persistent-hint
                    >
                    </v-text-field>
                  </div>
                </template>
                <span>{{ shippingSelectedName }} </span>
              </v-tooltip>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 元出荷No-->
            <div class="textbox-arrivalNo">
              <v-text-field
                readonly
                dense
                v-model="outListNo"
                maxlength="20"
                :label="$t('label.lbl_OriginalshippingNo')"
                class="txt-single"
              />
            </div>

            <span class="item-spacer">&nbsp;</span>
            <!-- 返品伝票No-->
            <div class="textbox-arrivalNo" v-if="!disableBtn">
              <v-text-field
                outlined
                dense
                v-model="returnsSid"
                maxlength="45"
                :label="$t('label.lbl_returnSlipNo')"
                class="txt-single"
                :rules="[rules.inputRequired]"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <div class="textbox-arrivalNo" v-else>
              <v-text-field
                outlined
                dense
                v-model="returnsSid"
                maxlength="45"
                :label="$t('label.lbl_returnSlipNo')"
                class="txt-single"
                :rules="[rules.inputRequired]"
                :readonly="true"
              />
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- カレンダー -->

            <!-- 有償無償-->
            <div style="float: left">
              <v-btn-toggle
                mandatory
                v-model="isPaid"
                color="blue darken-2"
                id="btn-toggle-btn-padding"
              >
                <v-btn
                  class="paid-btn"
                  v-for="item in freeCostDivList"
                  :key="item.value"
                  :value="item.value"
                  small
                  :disabled="disableBtn"
                >
                  {{ item.text }}</v-btn
                >
              </v-btn-toggle>
            </div>

            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <!-- 無償理由選択プルダウン-->
            <div class="search-autocomplete" style="float: left">
              <v-autocomplete
                dense
                class="list-single"
                v-model="freeReasonSelected"
                :items="freeReasonList"
                v-bind:readonly="disableBtn"
                :label="$t('label.lbl_freeReason')"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
          </v-row>
          <v-row>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 返品日-->
            <div class="date-style">
              <v-text-field
                dense
                readonly
                v-model="returnsDate"
                :label="$t('label.lbl_returnDate')"
                @change="changeDateFrom"
                class="txt-single date-style"
              ></v-text-field>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 出荷品質区分-->
            <div class="search-autocomplete">
              <v-text-field
                readonly
                dense
                v-model="arrivalStatusSelected"
                :label="$t('label.lbl_shippingArrivalStatus')"
                class="txt-single"
                persistent-hint
              ></v-text-field>
            </div>

            <!-- 返品品質区分-->
            <div class="search-autocomplete first-search-item" style="float: left">
              <v-autocomplete
                dense
                v-model="rtnProdStatus"
                :items="rtnProdStatusList"
                :label="$t('label.lbl_returnArrivalStatus')"
                class="txt-single"
                persistent-hint
                :hint="setRtnStatus()"
                :rules="[rules.inputRequired]"
                :readonly="disableBtn"
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <span class="require">*</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 責任区分-->
            <v-btn-toggle
              v-model="isRes"
              mandatory
              color="blue darken-2"
              class="toggle black--text"
            >
              <v-btn
                mandatory
                class="res-btn"
                v-for="item in blameDivList"
                :key="item.value"
                :value="item.value"
                small
                v-bind:disabled="isDisabledBlame"
              >
                {{ item.text }}</v-btn
              >
            </v-btn-toggle>
          </v-row>
          <v-row>
            <!--ボタン領域-->
            <div class="search-row-exeBtn">
              <!--戻るボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 戻るボタン -->
                <v-btn color="primary" class="api-btn" @click="backPage">{{
                  $t("btn.btn_back")
                }}</v-btn>
              </div>
              <div class="btn-area">
                <!--承認ボタン-->
                <v-btn
                  color="primary"
                  class="api-btn"
                  @click="approval()"
                  :disabled="this.approvalAll"
                  >{{ $t("btn.btn_approval") }}</v-btn
                >
                <!--修正登録ボタン-->
                <v-btn color="primary" class="api-btn" @click="insert()" :disabled="disableBtn">{{
                  $t("btn.btn_fixinsert")
                }}</v-btn>
              </div>
            </div>
          </v-row>
        </v-container>

        <!-- データテーブル -->
        <v-container fluid>
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            :page.sync="page"
            :ripple="false"
            :server-items-length="itemsPerPage"
            disable-filtering
            disable-pagination
            :hide-default-footer="true"
            height="530px"
            :options.sync="sortOptions"
          >
            <!-- ヘッダーロット -->
            <template v-slot:[`header.lot`]="{ header }">
              <span>
                <div>{{ header.text }}</div>
                <span>
                  {{
                    $t("label.lbl_dueDate") +
                    "／" +
                    $t("label.lbl_lotSubkey1") +
                    "／" +
                    $t("label.lbl_lotSubkey2")
                  }}
                </span>
              </span>
            </template>
            <!-- TODO: 出荷先の表示をここに記載する。（コード（名称）） -->
            <template v-slot:[`item.shippngCdNm`]="{ item }">
              <div v-if="item.shippngCdNm.length > 18">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-overflow" style="max-width: 200px" v-bind="attrs" v-on="on">
                      {{ item.shippngCdNm.substring(0, 18) + "..." }}
                    </div>
                  </template>
                  <div>{{ item.shippngCdNm }}</div>
                </v-tooltip>
              </div>
              <div v-else>
                <div>{{ item.shippngCdNm }}</div>
              </div>
            </template>
            <!-- 商品コード・品名 -->
            <template v-slot:[`item.productCn`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-overflow" style="max-width: 380px" v-bind="attrs" v-on="on">
                    {{ item.productCn }}<br />
                    {{ item.incidental }}<br />
                    {{ item.productName }}
                  </div>
                </template>
                <span
                  >{{ item.productCn }}<br />
                  {{ item.incidental }}<br />
                  {{ item.productName }}</span
                >
              </v-tooltip>
            </template>

            <!-- ロット・S/N -->
            <template v-slot:[`item.lot`]="{ item }">
              <div v-if="item.lotNo.length > 15 || item.dueDate.length > 15">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-overflow" style="width: 365px" v-bind="attrs" v-on="on">
                      {{ item.lotNo }}<br />
                      {{ item.dueDate }}
                    </div>
                  </template>
                  <div>{{ item.lotNo }}<br />{{ item.dueDate }}</div>
                </v-tooltip>
              </div>
              <div v-else>
                {{ item.lotNo }}<br />
                {{ item.dueDate }}
              </div>
            </template>

            <!-- 返品理由区分-->
            <template v-slot:[`item.returnReason`]="{ item }">
              <div id="table-productCd">
                <v-autocomplete
                  v-model="item.returnReason"
                  :items="ReasonList"
                  persistent-hint
                  :rules="[rules.inputRequired]"
                  disable-sort
                  dense
                  class="cursorProcd"
                  :disabled="item.approvalFlg == 1"
                ></v-autocomplete>
              </div>
            </template>
            <!-- 返品数量-->
            <template v-slot:[`item.returnQty`]="{ item, index }">
              <div id="table-productQty">
                <v-text-field
                  class="input-number"
                  dense
                  outlined
                  v-model="item.returnQty"
                  @change="commaMoveQty(item, index)"
                  :rules="[
                    rules.isNumber,
                    rules.inputRequired,
                    rules.limitNumLength,
                    rules.isRangeCheck,
                  ]"
                  clear-icon="mdi-close-circle"
                  clearable
                  :ref="'returnQty' + index"
                  :disabled="item.approvalFlg == 1"
                />
              </div>
            </template>
            <!-- 備考欄-->
            <template v-slot:[`item.remarks`]="{ item }">
              <div id="table-productQty">
                <v-text-field
                  class="text-box"
                  dense
                  outlined
                  :rules="[rules.inputRequired, rules.limitNumLength200]"
                  counter="200"
                  v-model="item.remarks"
                  clear-icon="mdi-close-circle"
                  clearable
                  :disabled="item.approvalFlg == 1"
                />
              </div>
            </template>
            <!-- 削除-->
            <template v-slot:[`item.deleteData`]="{ item }">
              <v-btn small @click="deleteItem(item)" text :disabled="item.approvalFlg == 1">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-container>

        <v-navigation-drawer absolute temporary v-model="openMenu">
          <sideMenu></sideMenu>
        </v-navigation-drawer>
        <!-- 戻るボタンを押下時出現ダイアログ -->
        <v-dialog v-model="updateDialogMessage" :max-width="800">
          <v-card>
            <v-card-title class="blue-grey lighten-3" primary-title>
              {{ $t("btn.btn_ok") }}
            </v-card-title>
            <v-card-text class="pa-4">
              <p>{{ backMessage }}</p>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="updateOk"> OK </v-btn>
              <v-btn @click="updateNg"> {{ $t("btn.btn_cancel") }} </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- チェックボックスダイアログ -->
        <v-dialog v-model="approvalDialogMsg" :max-width="800">
          <v-card>
            <v-card-title class="blue-grey lighten-3" primary-title>
              {{ $t("btn.btn_ok") }}
            </v-card-title>
            <v-card-text class="pa-4">
              <p>{{ alertMessage }}</p>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="okClicked"> OK </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="ConfirmDialog.isOpen" persistent="" no-click-animation>
          <ConfirmDialog
            :isShow.sync="ConfirmDialog.isOpen"
            :message="ConfirmDialog.message"
            :okAction="postApi"
            :redMessage="ConfirmDialog.redMessage"
            :screenFlag="ConfirmDialog.screenFlag"
            :changeFlag="ConfirmDialog.changeFlag"
          />
        </v-dialog>

        <!-- 確認メッセージダイアログモーダル-->
        <v-dialog v-model="infoDialog.isOpen" persistent="" no-click-animation>
          <SimpleDialog
            :isShow.sync="infoDialog.isOpen"
            :title="infoDialog.title"
            :message="infoDialog.message"
            :firstPageFlag="infoDialog.firstPageFlag"
            :secondPageFlag="infoDialog.secondPageFlag"
            :rtnapprovalFlg="infoDialog.rtnapprovalFlg"
          />
        </v-dialog>
      </v-form>
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import ConfirmDialog from "../../components/ConfirmDialog";
import Loading from "@/components/loading";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
export default {
  name: appConfig.SCREEN_ID.P_RTN_003,
  components: {
    Loading,
    SimpleDialog,
    ConfirmDialog,
    sideMenu,
    NavBar,
  },
  props: {
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // 入力チェック結果
    isInputCheck: false,
    sortItem: "",
    sortOptions: {},
    u_10: false,
    involce: "",
    // 輸送会社ダイアログ
    isOpenTruck: false,
    // 輸送会社データ項目
    truckInfo: { truck: "" },
    //取引先
    clientprop: "",
    //出荷先
    shippingprop: "",
    outListNo: "",
    returnsSid: "",
    shippingSelectedName: "",
    suppliersSelectedName: "",
    // 責任区分
    isRes: "01",
    isResBadValue: "01",
    // ロケーションダイアログ
    dialogLocation: false,
    // 返品日
    returnsDate: "",
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    itemsPerPageBtn: "10",
    // 出荷先
    shippingSelected: "",
    // 取引先
    suppliersSelected: "",
    // 発行条件(送り状)
    issueTermsSelected: "",
    // 発行条件(荷札)
    tagTermsSelected: "",
    // メニュー
    openMenu: null,
    // 出荷予定Fromカレンダー
    dateMenuFrom: false,
    // 出荷予定Toカレンダー
    dateMenuTo: false,
    blameDivList: [],
    // 承認ボタンフラグ
    approvalAll: false,
    // ボタン制御
    disableBtn: false,
    // API用clientSid
    suppliersNm: "",
    ReasonList: [],
    // 修正削除用リスト
    deleteList: [],
    // 確認メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      secondPageFlag: false,
      rtnapprovalFlg: false,
    },
    // 登録ボタン押下時確認メッセージ
    ConfirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
    },
    // 承認か修正登録か判断
    confirmflag: "",
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    // バリデーションチェックメッセージダイアログ
    alertMessage: "",
    // ヘッダ
    headerItems: [
      // 品番/品名
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "productCn",
        width: "20%",
        align: "left",
        sortable: false,
      },
      // ロット
      {
        text: i18n.tc("label.lbl_lot") + "／" + i18n.tc("label.lbl_serial"),
        value: "lot",
        width: "20%",
        align: "left",
        sortable: false,
      },
      // 出荷実績数
      {
        text: i18n.tc("label.lbl_shipAchieveQty"),
        value: "shipAchieveQty",
        width: "10%",
        align: "right",
        sortable: false,
      },
      // 返品理由区分
      {
        text: i18n.tc("label.lbl_returnreason"),
        value: "returnReason",
        width: "10%",
        align: "left",
        sortable: false,
        class: "asta",
      },
      // 返品数量
      {
        text: i18n.tc("label.lbl_returncnt"),
        value: "returnQty",
        width: "10%",
        align: "left",
        sortable: false,
        class: "asta",
      },
      // 備考
      {
        text: i18n.tc("label.lbl_remarksTextbox"),
        value: "remarks",
        width: "25%",
        align: "left",
        sortable: false,
        class: "asta",
      },
      // 削除
      {
        text: i18n.tc("btn.btn_delete"),
        value: "deleteData",
        width: "5%",
        align: "center",
        sortable: false,
      },
    ],
    // 修正削除画面
    detailDialogData: {},
    // 総件数
    totalCount: 0,
    inputList: [],
    // 取引先プルダウン
    supplierList: [],
    // 出荷先プルダウン
    shippingList: [],
    // 一覧index
    editedIndex: -1,
    // 出荷先API用Sid
    shippingNm: "",
    // 前画面から受け取る値
    arrAchieveDateFrom: "",
    arrAchieveDateFromCal: "",
    arrAchieveDateTo: "",
    arrAchieveDateTocal: "",
    approvalDialogMsg: false,
    // 出荷品質区分
    arrivalStatusSelected: "",
    // 返品品質区分
    rtnProdStatus: "",
    rtnProdStatusList: [],
    // 営業所SID
    officeSid: "",
    // 出荷実績数
    shipAchieveQty: 0,
    // 無償理由
    freeReasonSelected: "",
    // 有償/無償
    isPaid: "",
    freeCostDivList: [],
    // 無償理由プルダウン
    freeReasonList: [],
    isDisabledFreeReason: true,
    // 責任区分非活性
    isDisabledBlame: true,
    // 入力チェック
    rules: {
      // 取引先未入力チェック
      supplierRequired: (value) => !!value || i18n.tc("check.chk_inputSupplier"),
      // 未入力チェック
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      // 日付形式チェック(yyyy/MM/dd)
      yyyymmdd: (value) =>
        dateTimeHelper.validDate(commonUtil.zen2han(value), "/") || i18n.tc("check.chk_inputDate"),
      isNumber: (value) =>
        (!!value && !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", "")))) ||
        i18n.tc("check.chk_inputNumber"),
      limitNumLength: (value) =>
        value == null ||
        String(value).replaceAll(",", "").length < 10 ||
        i18n.tc("check.chk_limitNumLength"),
      limitNumLength200: (value) =>
        value == null || value.length <= 200 || i18n.tc("check.chk_limitLength_1-200"),
      isInteger: (value) =>
        Number.isInteger(Number(commonUtil.zen2han(value).replaceAll(",", ""))) ||
        i18n.tc("check.chk_inputNotDecimal"),
      isRangeCheck: (value) =>
        0 < Number(commonUtil.zen2han(value).replaceAll(",", "")) || i18n.tc("check.chk_inputMore"),
    },
  }),
  methods: {
    init() {
      // 伝票一覧から受け取った値をプルダウンにセット
      this.suppliersSelected = this.$route.params.suppliersNm; // 取引先
      this.shippingSelected = this.$route.params.toSid; // 出荷先
      this.outListNo = this.$route.params.outListNo; // 元出荷先No
      this.returnsSid = this.$route.params.returnsNo; // 返品伝票No
      this.returnsDate = this.$route.params.returnsDateForDisplay; // 返品日
      this.returnsDateForApi = this.$route.params.returnsDateForApi; // 返品日

      this.isRes = this.$route.params.blameDiv; // 責任区分
      this.arrivalStatusSelected = this.$route.params.shipQualityDivName; // 出荷品質区分名
      this.rtnProdStatus = this.$route.params.returnsQualityDiv; // 返品品質区分
      this.officeSid = this.$route.params.officeSid; // 営業所SID
      // 検索時保持
      this.searchProdStatus = this.$route.params.returnsQualityDiv; // 品質区分
      this.searchReturnsSid = this.$route.params.returnsNo; // 返品伝票No
      // 返品FromTo
      this.arrAchieveDateFrom = this.$route.params.arrAchieveDateFrom;
      this.arrAchieveDateFromCal = this.$route.params.arrAchieveDateFromCal;
      this.arrAchieveDateTo = this.$route.params.arrAchieveDateTo;
      this.arrAchieveDateToCal = this.$route.params.arrAchieveDateToCal;
      // 返品伝票GET呼び出し時に必要な値を受け取りAPIを実行
      this.outListSubSid = this.$route.params.outListSubSid; // 出荷伝票明細SID
      this.outListSid = this.$route.params.outListSid; // 出荷伝票SID
      this.getReturnDetails();
      this.getCustomInfo();
      this.getMstCode();
    },
    // 修正登録ボタン押下**登録しますかのアラート**
    insert() {
      if (this.$refs.form.validate()) {
        for (var j = 0; j < this.inputList.length; j++) {
          // 出荷実績数よりも、返品数量が多い場合
          if (
            Number(this.inputList[j].returnQty.replaceAll(",", "")) >
            Number(this.inputList[j].shipAchieveQty.replaceAll(",", ""))
          ) {
            this.approvalDialogMsg = true;
            this.alertMessage = messsageUtil.getMessage("P-RTN-001_002_E", [
              i18n.tc("btn.btn_fixinsert"),
            ]);
            this.loadingCounter = 0;
            return;
          }
        }

        this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
        this.ConfirmDialog.isOpen = true;
        this.ConfirmDialog.screenFlag = true;
        this.ConfirmDialog.changeFlag = false;
        this.confirmflag = "1"; // 「修正登録」の際は１
      }
    },
    // 承認された明細の確認
    isApproval() {
      let count = 0;
      for (var i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].approvalFlg == "0") {
          count += 1;
        }
      }
      if (count >= 1) {
        this.approvalAll = false;
        this.disableBtn = false;
      } else if (count == 0) {
        this.approvalAll = true;
        this.disableBtn = true;
      }
    },
    // 承認ボタン押下**登録しますかのアラート**
    approval() {
      if (this.$refs.form.validate()) {
        for (var j = 0; j < this.inputList.length; j++) {
          // 出荷実績数よりも、返品数量が多い場合
          if (
            Number(this.inputList[j].returnQty.replaceAll(",", "")) >
            Number(this.inputList[j].shipAchieveQty.replaceAll(",", ""))
          ) {
            this.approvalDialogMsg = true;
            this.alertMessage = messsageUtil.getMessage("P-RTN-001_002_E", [
              i18n.tc("btn.btn_approval"),
            ]);
            this.loadingCounter = 0;
            return;
          }
        }

        if (
          // 返品品質区分、返品伝票No、返品理由区分、返品数量、備考の変更チェック
          this.searchProdStatus != this.rtnProdStatus ||
          this.searchReturnsSid != this.returnsSid ||
          this.detailReturnReason != this.inputList[0].returnReason ||
          this.detailReturnQty != this.inputList[0].returnQty ||
          this.detailRemarks != this.inputList[0].remarks
        ) {
          this.ConfirmDialog.message = messsageUtil.getMessage("P-RTN-003_001_E");
          this.ConfirmDialog.isOpen = true;
          this.ConfirmDialog.screenFlag = true;
          this.ConfirmDialog.changeFlag = false;
          this.confirmflag = "2"; // 「承認の際は２」
        } else {
          this.ConfirmDialog.message = messsageUtil.getMessage("P-CNT-006_001_E");
          this.ConfirmDialog.isOpen = true;
          this.ConfirmDialog.screenFlag = true;
          this.ConfirmDialog.changeFlag = false;
          this.confirmflag = "2"; // 「承認の際は２」
        }
      }
    },
    okClicked() {
      this.approvalDialogMsg = false;
    },
    // 戻るボタン押下
    backPage() {
      this.updateDialogMessage = true;
      this.backMessage = messsageUtil.getMessage("P-RCV-004_003_W");
    },
    // 戻るボタンダイアログ：OK押下
    updateOk() {
      this.updateDialogMessage = false;
      this.$router.push({
        name: appConfig.SCREEN_ID.P_RTN_002,
        params: {
          arrAchieveDateFrom: this.arrAchieveDateFrom,
          arrAchieveDateFromCal: this.arrAchieveDateFromCal,
          arrAchieveDateTo: this.arrAchieveDateTo,
          arrAchieveDateToCal: this.arrAchieveDateToCal,
          suppliersNm: this.suppliersSelected,
          returnsSid: this.$route.params.returnsSid,
          backpage: "ReturnSlipApproval",
        },
      });
    },
    // 戻るボタンダイアログ：NG押下
    updateNg() {
      this.updateDialogMessage = false;
    },
    // 承認APIと修正削除API
    postApi() {
      if (this.$refs.form.validate()) {
        // ローディング画面表示ON
        this.loadingCounter = 1;

        const body = this.$httpClient.createRequestBodyConfig();
        //★必須パラメータ
        body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_RTN_003;

        var returnsApproval = [];
        if (this.confirmflag == "2") {
          for (var m = 0; m < this.inputList.length; m++) {
            returnsApproval.push({
              opeDispName: i18n.tc("label.lbl_ReturnSlipApperovalpc"),
              returnsApproval: "", //承認フラグ
              returnsSid: this.inputList[m].returnsSid, //返品伝票SID
              returnsQuantity: this.inputList[m].returnQty.replaceAll(",", ""), //返品数量
              itemStandardSid: this.inputList[m].itemStandardSid, //商品標準SID
              fromSid: this.inputList[m].fromSid, //入荷元
              toSid: this.inputList[m].toSid, //出荷先
              blameDiv: this.isRes, //責任区分
              clientSid: this.inputList[m].clientSid, //取引先SID
              itemCd: this.inputList[m].itemCd, //品番
              outListSubSid: this.inputList[m].outListSubSid, //返品伝票明細SID
              lotNo: this.inputList[m].lot ? this.inputList[m].lot : "", //ロット
              remarks: this.inputList[m].remarks, //備考
              updateDatetime: this.inputList[m].updateDatetime, //更新日時
              freeCostDiv: this.isPaid, //有償無償区分
              qualityDiv: this.rtnProdStatus, //返品品質区分
              returnsNo: this.returnsSid, // 返品伝票No
              reasonDiv: this.inputList[m].returnReason, // 返品理由区分
              officeSid: this.officeSid, // 営業所SID
              returnsDate: this.inputList[m].returnsDate, // 返品日
              reasonCd: this.freeReasonSelected, // 理由CD
              opeHistSid: this.inputList[m].opeHistSid, // 操作履歴SID
            });
          }
          body.reqIdv.returnsApproval = returnsApproval.slice();
          // console.debug("postApi() Body", body);

          return new Promise((resolve, reject) => {
            this.$httpClient
              // 接続先のAPI_URLを入力
              .securePost(appConfig.API_URL.BIZ_RETURNSAPPROVAL, body, appConfig.APP_CONFIG)
              .then((response) => {
                // console.debug("postApi() Response", response);
                const jsonData = JSON.parse(JSON.stringify(response.data));

                // 正常時
                if (jsonData.resCom.resComCode == "000") {
                  this.infoDialog.message = messsageUtil.getMessage("P-CNT-006_002_C");
                  this.infoDialog.title = "結果";
                  this.infoDialog.isOpen = true;
                  this.infoDialog.screenFlag = true;
                  this.infoDialog.rtnapprovalFlg = true;
                  this.infoDialog.firstPageFlag = false;

                  resolve(response);
                  // エラー時
                } else {
                  this.infoDialog.message = jsonData.resCom.resComMessage;
                  this.infoDialog.title = appConfig.DIALOG.title;
                  this.infoDialog.isOpen = true;
                  this.infoDialog.screenFlag = true;
                  this.infoDialog.firstPageFlag = true;

                  reject();
                }
              })
              .catch((ex) => {
                this.infoDialog.message = ex;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                this.infoDialog.firstPageFlag = true;

                reject();
              })
              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
              });
          });
        } else if (this.confirmflag == "1") {
          if (this.$refs.form.validate()) {
            for (var n = 0; n < this.deleteList.length; n++) {
              returnsApproval.push({
                returnsNo: this.returnsSid, //返品伝票No
                returnsSid: this.deleteList[n].returnsSid, //返品伝票SID
                clientSid: this.suppliersSelected, //取引先SID
                toSid: this.shippingSelected, //出荷先
                outListSubSid: this.deleteList[n].outListSubSid, //出荷伝票明細SID
                // returnsDate: dateTimeHelper.convertUTC(this.deleteList[n].returnsDate), //返品日
                returnsDate: this.deleteList[n].returnsDate,
                blameDiv: this.isRes, //責任区分
                returnsQuantity: Number(this.deleteList[n].returnQty.replaceAll(",", "")), //返品数量
                reasonDiv: this.deleteList[n].returnReason, //理由区分
                remarks: this.deleteList[n].remarks, //備考
                deleteFlg: this.deleteList[n].deleteFlg, //削除＝１、更新＝０
                qualityDiv: this.rtnProdStatus, //返品品質区分
                officeSid: this.officeSid, // 営業所SID
                freeCostDiv: this.isPaid, // 有償無償
                reasonCd: this.freeReasonSelected, // 理由CD
                opeHistSid: this.deleteList[n].opeHistSid, // 操作履歴SID
                updateDatetime: this.deleteList[n].updateDatetime, //更新日時
              });
            }
            body.reqIdv.returns = returnsApproval.slice();
            // console.debug("postApi() Body", body);

            return new Promise((resolve, reject) => {
              this.$httpClient
                // 接続先のAPI_URLを入力
                .securePost(appConfig.API_URL.BIZ_RETURNS, body, appConfig.APP_CONFIG)
                .then((response) => {
                  // console.debug("postApi() Response", response);
                  const jsonData = JSON.parse(JSON.stringify(response.data));

                  // 正常時
                  if (jsonData.resCom.resComCode == "000") {
                    this.infoDialog.message = messsageUtil.getMessage("P-CNT-006_002_3");
                    this.infoDialog.title = "結果";
                    this.infoDialog.isOpen = true;
                    this.infoDialog.screenFlag = true;
                    this.infoDialog.rtnapprovalFlg = true;
                    this.infoDialog.firstPageFlag = false;

                    resolve(response);
                    // エラー時
                  } else {
                    this.infoDialog.message = jsonData.resCom.resComMessage;
                    this.infoDialog.title = appConfig.DIALOG.title;
                    this.infoDialog.isOpen = true;
                    this.infoDialog.screenFlag = true;
                    this.infoDialog.firstPageFlag = true;
                    reject();
                  }
                })
                .catch((ex) => {
                  this.infoDialog.message = ex;
                  this.infoDialog.title = appConfig.DIALOG.title;
                  this.infoDialog.isOpen = true;
                  this.infoDialog.screenFlag = true;
                  this.infoDialog.firstPageFlag = true;
                  reject();
                })
                .finally(() => {
                  // ローディング画面表示OFF
                  this.loadingCounter = 0;
                });
            });
          }
        }
      }
    },
    /**
     * 取引先一覧と出荷先一覧を取得します。
     */
    getCustomInfo(jstOutScheduleDate) {
      // ローディング画面の表示をONにします。
      this.loadingCounter = 1;

      // 取引先一覧を取得します。
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(jstOutScheduleDate)
      );
      // 非同期処理を開始します。
      Promise.all([clientList])
        // 成功時の処理です。
        .then((result) => {
          // ヘッダー部の取引先一覧を設定します。
          this.supplierList = result[0];

          // 取引先一覧に対する繰り返し処理です。
          for (let i = 0; i < this.supplierList.length; i++) {
            // 選択された取引先と一致する場合の処理です
            if (this.suppliersSelected == this.supplierList[i].value) {
              // 選択された取引先に取引先一覧の要素を設定します。
              this.suppliersSelectedName = this.supplierList[i].text;
            }
          }

          // 出荷先一覧を取得します。
          this.getSuppliersSelected(this.suppliersSelected);
        })
        // 失敗時の処理です。
        .catch((ex) => {
          this.infoDialog.message = ex;

          this.infoDialog.title = appConfig.DIALOG.title;

          this.simpleDialog = true;

          this.infoDialog.firstPageFlag = true;
        })
        // 成功・失敗に関わらず実行される処理です。
        .finally(() => {
          // ローディング画面の表示をOFFにします。
          this.loadingCounter = 0;
        });
    },
    /**
     * 出荷先一覧を取得します。
     */
    getSuppliersSelected() {
      // 選択された取引先のSIDを取得します。
      let officeCd = this.supplierList.find(
        (element) => element.value === this.suppliersSelected
      ).clientSid;

      // 出荷先一覧を取得します。
      const toList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.FROMTO,
        dateTimeHelper.convertUTC(this.arrivalScheduleDate),
        undefined,
        officeCd
      );

      // 非同期処理を開始します。
      Promise.all([toList])
        // 成功時の処理です。
        .then((result) => {
          // ヘッダー部の出荷先一覧を設定します。
          this.shpList = result[0];

          // 出荷先一覧に対する繰り返し処理です。
          for (let i = 0; i < this.shpList.length; i++) {
            // 出荷先SIDが一致する場合の処理です。
            if (this.shippingSelected == this.shpList[i].value) {
              // 出荷先に出荷先一覧の要素を設定します。
              this.shippingSelectedName = this.shpList[i].displayText;
            }
          }
        })
        // 失敗時の処理です。
        .catch((ex) => {
          this.infoDialog.message = ex;

          this.infoDialog.title = appConfig.DIALOG.title;

          this.infoDialog.isOpen = true;

          this.infoDialog.firstPageFlag = true;
        })
        // 成功・失敗に関わらず実行される処理です。
        .finally(() => {
          // ローディング画面の表示をOFFにします。
          this.loadingCounter = 0;
        });
    },
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 返品理由
      const reasonList = getParameter.getCodeMst(appConfig.CODETYPE.RETURNREASON_DIV);
      // 責任区分
      const blameList = getParameter.getCodeMst(appConfig.CODETYPE.IN_BLAME_DIV);
      // 品質区分
      const qualityList = getParameter.getCodeMst(appConfig.CODETYPE.IN_QUALITY_DIV);
      // 有償無償区分
      const freeCostList = getParameter.getCodeMst(appConfig.CODETYPE.IN_FREECOST_DIV);
      // 理由コード
      const reasonCodeList = getParameter.getCodeMst(appConfig.CODETYPE.IN_REASON_DIV);
      Promise.all([reasonList, blameList, qualityList, freeCostList, reasonCodeList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.ReasonList = result[0];
          result[1].splice(0, 1);
          this.blameDivList = result[1];
          this.rtnProdStatusList = result[2];
          this.freeCostDivList = result[3];
          this.freeReasonList = result[4];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    // 返品伝票承認GET
    getReturnDetails() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.blameDiv = this.isRes; //責任区分
      config.params.returnsDate = this.returnsDateForApi.substr(0, 10); //返品日
      config.params.outListNo = this.outListNo; //出荷伝票No
      config.params.returnsNo = this.returnsSid; //返品伝票No
      config.params.clientSid = this.suppliersSelected; //取引先SID
      config.params.toSid = this.shippingSelected; //出荷先
      // console.debug("getReturnDetails() Config", config);

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.BIZ_RETURNSAPPROVALDETAIL, config)
          .then((response) => {
            // console.debug("getReturnDetails() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              jsonData.resIdv.returnsDetail.forEach((row) => {
                this.freeReasonSelected = row.reasonCd; //理由CD名
                let lotNoObj = commonUtil.convertLotNo(row.lotNo);
                list.push({
                  returnsSid: row.returnsSid, //返品伝票SID
                  returnReason: row.reasonDiv, //返品理由
                  returnQty: commonUtil.formatToCurrency(Number(row.returnsQuantity)), //返品数量
                  returnsDate: row.returnsDate, //返品日
                  remarks: row.remarks, //備考
                  outListSubSid: row.outListSubSid, //出荷伝票明細SID
                  itemCd: row.itemCd,
                  productCn: row.itemCd.substring(0, 50).trimRight(), //品番品名(品番)
                  incidental: row.itemCd.slice(-50).trimRight(), //品番品名(付帯)
                  productName: row.itemName,
                  lot: row.lotNo, //ロット
                  lotNo: lotNoObj.lot, //ロット/S/N(ロット)
                  dueDate: lotNoObj.dueDate, //期限日 + 付属キー１ + 付属キー２
                  qualityDiv: row.qualityDiv, //品質区分
                  itemStandardSid: row.itemStandardSid, //商品標準SID
                  clientSid: row.clientSid, //取引先SID
                  freeCostDiv: row.freeCostDiv, //有償無償区分
                  blameDiv: row.blameDiv, //責任区分
                  fromSid: row.fromSid, //入荷元
                  toSid: row.toSid, //出荷先
                  deleteFlg: "0", //削除フラグ
                  updateDatetime: row.updateDatetime, //更新日時
                  approvalFlg: row.approvalFlg, //承認フラグ
                  shipAchieveQty: commonUtil.formatToCurrency(Number(row.outStock)), //出荷実績数
                  reasonCd: row.reasonDiv, //理由CD
                  opeHistSid: row.opeHistSid, //操作履歴SID
                });
              });
              this.inputList = list;
              // this.deleteList = list;
              this.deleteList = Object.assign([], list);
              resolve(response);
              // 詳細保持
              this.detailReturnReason = this.inputList[0].returnReason; //返品理由
              this.detailReturnQty = this.inputList[0].returnQty; //返品数量
              this.detailRemarks = this.inputList[0].remarks; //備考
              // 値をセット
              this.isPaid = this.inputList[0].freeCostDiv; // 有償無償
              this.isRes = this.inputList[0].blameDiv; // 責任区分
              // this.freeReasonSelected = this.inputList[0].reasonCd; //理由CD名
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
            this.isApproval();
          });
      });
    },
    // 削除ボタン
    deleteItem(item) {
      this.editedIndex = this.inputList.indexOf(item);
      // 削除したリストを退避する
      // this.deleteList.push(this.inputList[this.editedIndex]);
      this.deleteList[this.editedIndex].deleteFlg = "1";

      this.inputList.splice(this.editedIndex, 1);
    },
    link() {
      location.href = this.returnURL;
    },
    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 10;
      this.page = 1;
      this.getApiList();
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.getApiList();
    },
    /**
     * メニューダイアログ
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },
    clearAll() {
      this.inputList.splice(0);
    },
    // 返品数量カンマ処理
    commaMoveQty(val, index) {
      var comma = commonUtil.zen2han(val.returnQty).replaceAll(",", "");
      comma = comma.replace(/[^0-9]/gi, "");
      if (comma.length > 9) {
        comma = comma.substring(0, 9);
      }
      if (!isNaN(Number(comma))) {
        this.inputList[index].returnQty = commonUtil.formatToCurrency(Number(comma));
      }
    },

    /**
     * 出荷予定登録画面：取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },

    /**
     * 出荷予定登録画面：出荷先コードリストボックス取得処理
     */
    setSrhShipping() {
      for (var i = 0; i < this.shippingList.length; i++) {
        if (this.shippingList[i].value == this.shippingSelected) {
          return this.shippingList[i].name;
        }
      }
    },

    /**
     * 返品品質区分セット
     */
    setRtnStatus() {
      for (var i = 0; i < this.rtnProdStatusList.length; i++) {
        if (this.rtnProdStatusList[i].value == this.rtnProdStatus) {
          return this.rtnProdStatusList[i].name;
        }
      }
    },

    clickPop(val) {
      let myName = event.target;
      let pop = val;
      if (val != null && val.length > 3) {
        pop = val.replaceAll(",", "");
      }
      myName.value = pop;
    },
    changeDateFrom(val, index) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }

      if (dateTimeHelper.validDate(strDt, "/")) {
        // 選択した行の有効開始日も更新
        this.inputList[index].validFrom = strDt;
        this.inputList[index].validFromCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      }
    },

    /**
     * 返品伝票承認画面：責任区分活性化処理
     */
    activateButton() {
      if (this.rtnProdStatus == "") {
        return true;
      } else if (this.rtnProdStatus == "01") {
        return true;
      } else if (this.rtnProdStatus == "02") {
        return false;
      }
    },
  },

  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    //有償無償変更時
    isPaid: function (newVal) {
      if (newVal == "02") {
        this.isDisabledFreeReason = false;
      } else {
        this.isDisabledFreeReason = true;
        this.freeReasonSelected = "";
      }
    },
    shippingSelected(val) {
      this.shippingprop = val;
    },
    dialog(val) {
      val || this.close();
    },
    suppliersSelected(val) {
      //取引先が選択されたらその値を子画面に渡す
      this.clientprop = val;
    },
    page: function (newValue) {
      this.page = newValue;
      this.getApiList();
    },
    rtnProdStatus: function (newVal) {
      if (newVal == "02") {
        this.isDisabledBlame = false;
        if (this.isResBadValue == "01") {
          this.isRes = "02";
        } else {
          this.isRes = this.isResBadValue;
        }
      } else {
        this.isResBadValue = this.isRes;
        this.isRes = "01";
        this.isDisabledBlame = true;
      }
    },
    sortOptions: {
      handler(items) {
        // console.debug("watch sortByApi", items);
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? "desc" : "asc";
          if (sortBy == "shipNo") {
            sortBy = "outListNo";
          } else if (sortBy == "shippingAchieveDateDate") {
            sortBy = "outScheduleDate";
          }
          this.sortItem = sortBy + " " + ascdesc;
          this.getApiList();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}

.from_to {
  font-weight: bold;
}

.toatlNum {
  margin-left: 10px;
}

.link {
  text-decoration: none;
}
</style>
